<template>
  <!-- Content Wrapper. Contains page content -->
  <div class="content-wrapper">
    <!-- Content Header (Page header) -->
    <div class="content-header">
      <div class="container-fluid">
        <div class="row mb-2">
          <div class="col-sm-6">
            <h1>Financial Reports</h1>
          </div><!-- /.col -->
          <div class="col-sm-6">
            <ol class="breadcrumb float-sm-right">
              <li class="breadcrumb-item">Home</li>
              <li class="breadcrumb-item">Reports</li>
              <li class="breadcrumb-item active">Financial Report</li>
            </ol>
          </div><!-- /.col -->
        </div><!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content-header -->

    <!-- Main content -->
    <div class="content">
      <div class="container-fluid">
        <div class="row">
          <!-- /.col-md-6 -->
          <div class="col-lg-12">
            <div class="card">
              <Loader v-if="loading" style="height: 100%" />
              <div class="card-header">
                <div class="input-group mb-0">
                  <div class="mr-2">
                    <date-range-picker class="text-dark"
                      v-model="datePickerSettings.dateRange"
                      :locale-data="datePickerSettings.locale"
                      :max-date="datePickerSettings.maxDate"
                      ref="picker"
                      @update="loadDataPayment"
                      v-tooltip="{ content: '<b>Select Date Range</b>', placement: 'right', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"
                    >
                      <template #ranges="ranges">
                        <div class="ranges">
                          <ul>
                            <li
                              v-for="(range, name) in datePickerSettings.ranges"
                              :key="name"
                              @click="ranges.clickRange(range)"
                            >
                              {{ name }}
                            </li>
                          </ul>
                        </div>
                      </template>
                      <!--    footer slot-->
                      <!-- <div slot="footer" @clickApply="alert('apply')" class="slot"></div> -->
                    </date-range-picker>
                    
                    <download-excel
                      class="btn btn-default pull-right ml-2"
                      :fetch = "fetchExcelData"
                      :fields="json_fields"
                      :before-generate = "startDownload"
                      :before-finish = "finishDownload"
                      :header="header"
                      :worksheet="worksheet"
                      :name="filename"
                      v-show='this.payments.total != 0'
                      v-tooltip="{ content: '<b>Export to Excel</b>', placement: 'right', classes: ['info'], targetClasses: ['it-has-a-tooltip'], offset: 10, delay: { show: 500, hide: 300, }, }"
                    >
                      <i class="fa fa-file-excel-o" aria-hidden="true"></i>
                    </download-excel>
                  </div>
                  <h1 class="card-title mt-2">&nbsp;</h1>
                </div>
              </div>
              <div v-if="loading" class="card-body p-0">
                <table class="table borderless mb-3">
                 <tr>
                    <td colspan="3" class="border-bottom" style="text-align: center">
                      <b>AS-IS/BALAYONG BWP-RURAL WATERWORKS & SANITATION ASSOCIATION, INC.</b><br/>
                      <b>COLLECTION REPORT</b><br/>
                      <b>{{ datePickerSettings.dateRange.startDate | customdate2 }} - {{ datePickerSettings.dateRange.endDate | customdate2 }}</b><br/>
                    </td>
                  </tr>
               </table>
               <table class="table table-bordered">
                 <thead>
                  <tr class="bg-primary">
                    <th class="align-middle text-center" scope="col" width="10px">#</th>
                    <th class="align-middle text-center" scope="col">PAYMENT DATE</th>
                    <th class="align-middle text-center" scope="col">OR NUMBER</th>
                    <th class="align-middle text-center" scope="col">CONSUMER NUMBER</th>
                    <th class="align-middle text-center" scope="col">CONSUMER NAME</th>
                    <th class="align-middle text-center" scope="col">TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td colspan="6">
                      <div class="row justify-content-center mt-5">
                          <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                              <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                              <g fill-rule="nonzero" stroke="#D9D9D9">
                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                              </g>
                            </g>
                          </svg>                  
                      </div>
                      <div class="row justify-content-center mb-5 text-muted">
                        <p>No records found</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
               </table>
              </div>
              <div v-else class="card-body p-0">
               <table class="table borderless mb-3">
                 <tr>
                    <td colspan="3" class="border-bottom" style="text-align: center">
                      <b>AS-IS/BALAYONG BWP-RURAL WATERWORKS & SANITATION ASSOCIATION, INC.</b><br/>
                      <b>COLLECTION REPORT</b><br/>
                      <b>{{ datePickerSettings.dateRange.startDate | customdate2 }} - {{ datePickerSettings.dateRange.endDate | customdate2}}</b><br/>
                    </td>
                  </tr>
               </table>
               <table class="table table-bordered">
                <thead>
                  <tr class="bg-primary">
                    <th class="align-middle text-center" scope="col" width="10px">#</th>
                    <th class="align-middle text-center" scope="col">PAYMENT DATE</th>
                    <th class="align-middle text-center" scope="col">OR NUMBER</th>
                    <th class="align-middle text-center" scope="col">CONSUMER NUMBER</th>
                    <th class="align-middle text-center d-none d-md-block" scope="col">CONSUMER NAME</th>
                    <th class="align-middle text-center" scope="col">TOTAL</th>
                  </tr>
                </thead>
                <tbody>
                  <tr v-show="payments.total==0">
                    <td colspan="6">
                      <div class="row justify-content-center mt-5">
                          <svg width="64" height="41" viewBox="0 0 64 41" xmlns="http://www.w3.org/2000/svg">
                            <g transform="translate(0 1)" fill="none" fill-rule="evenodd">
                              <ellipse fill="#F5F5F5" cx="32" cy="33" rx="32" ry="7"></ellipse>
                              <g fill-rule="nonzero" stroke="#D9D9D9">
                                <path d="M55 12.76L44.854 1.258C44.367.474 43.656 0 42.907 0H21.093c-.749 0-1.46.474-1.947 1.257L9 12.761V22h46v-9.24z"></path>
                                <path d="M41.613 15.931c0-1.605.994-2.93 2.227-2.931H55v18.137C55 33.26 53.68 35 52.05 35h-40.1C10.32 35 9 33.259 9 31.137V13h11.16c1.233 0 2.227 1.323 2.227 2.928v.022c0 1.605 1.005 2.901 2.237 2.901h14.752c1.232 0 2.237-1.308 2.237-2.913v-.007z" fill="#FAFAFA"></path>
                              </g>
                            </g>
                          </svg>                  
                      </div>
                      <div class="row justify-content-center mb-5 text-muted">
                        <p>No records found</p>
                      </div>
                    </td>
                  </tr>
                </tbody>
                <tbody v-for="payment in payments.data" :key="payment.id">
                  <tr>
                    <td class="align-middle text-center" width="10px">
                      <a role="button"
                          class="text-primary"
                          data-toggle="collapse" 
                          v-bind:href="'#collapse' + payment.id" 
                          aria-expanded="true"
                          v-on:click="toggleItem(payment.id)">
                        <i :class="[isActive(payment.id) ? 'fa-minus' : 'fa-plus']" class="more-less fa"></i>
                      </a>
                    </td>
                    <td class="align-middle text-left" scope="row">{{ payment.created_at | customdate5 }} <small class="text-muted pull-right"><i class="fa fa-clock-o"></i>{{ payment.created_at | customtime }}</small></td>
                    <td class="align-middle text-center" data-title="OR NO.">{{ payment.or_no }}</td>
                    <td class="align-middle text-center" data-title="CONSUMER NO">{{ payment.consumer.Consumercode }}</td>
                    <td class="align-middle text-center d-none d-md-block" data-title="CONSUMER NAME">{{ payment.consumer_name }}</td>
                    <td class="align-middle text-right" data-title="TOTAL"><b>{{ payment.fee_total }}</b></td>
                  </tr>
                  <tr class="collapse" v-bind:id="'collapse' + payment.id">
                    <td colspan="6" style="background-color: #67727c">
                      <div class="row">
                        <div class="col-md-3">
                          <div class="card card-primary card-outline">
                            <div class="card-body box-profile">
                              <div>
                                <ul class="list-group list-group-unbordered mb-3">
                                  <li class="list-group-item d-sm-none"><b class="ml-2">Consumer Name</b> <span class="float-right mr-2">{{ payment.consumer_name }}</span></li>
                                  <li class="list-group-item"><b class="ml-2">Membership Fee</b> <span class="float-right mr-2">{{ payment.fee_membership }}</span></li>
                                  <li class="list-group-item"><b class="ml-2">Connection Fee</b> <span class="float-right mr-2">{{ payment.fee_connection }}</span></li>
                                  <li class="list-group-item"><b class="ml-2">Reconnection Fee</b> <span class="float-right mr-2">{{ payment.fee_reconnection }}</span></li>
                                  <li class="list-group-item"><b class="ml-2">Service Fee</b> <span class="float-right mr-2">{{ payment.fee_service }}</span></li>
                                  <li class="list-group-item"><b class="ml-2">Threading Fee</b> <span class="float-right mr-2">{{ payment.fee_threading }}</span></li>
                                  <li class="list-group-item"><b class="ml-2">Water Meter</b> <span class="float-right mr-2">{{ payment.fee_meter }}</span></li>
                                  <li class="list-group-item"><b class="ml-2">Materials</b> <span class="float-right mr-2">{{ payment.fee_materials }}</span></li>
                                  <li class="list-group-item"><b class="ml-2">Others</b> <span class="float-right mr-2">{{ payment.fee_others }}</span></li>
                                </ul>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div class="col-md-9">
                          <div class="card card-primary card-outline">
                            <div class="card-body box-profile">
                              <div>
                                <table class="table table-striped responsive-table m-0 p-0">
                                  <thead>
                                    <tr>
                                      <th class="align-middle text-center" scope="col">READING DATE</th>
                                      <th class="align-middle text-center" scope="col">PREVIOUS RDG.</th>
                                      <th class="align-middle text-center" scope="col">PRESENT RDG.</th>
                                      <th class="align-middle text-center" scope="col">CONSUMPTION</th>
                                      <th class="align-middle text-center" scope="col">WATER FEE/RATE</th>
                                      <th class="align-middle text-center" scope="col">DISCOUNT</th>
                                      <th class="align-middle text-center" scope="col">SURCHARGE</th>
                                      <th class="align-middle text-center" scope="col">AMOUNT DUE</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    <tr class="border-top" v-for="trxns in payment.transactions" :key="trxns.id">
                                      <th class="align-middle text-right" scope="row"><b>{{trxns.reading_date | customdate2}}</b></th>
                                      <td class="align-middle text-right" data-title="PREVIOUS RDG.">{{trxns.prev_reading}}</td>
                                      <td class="align-middle text-right" data-title="PRESENT RDG.">{{trxns.pres_reading}}</td>
                                      <td class="align-middle text-right" data-title="CONSUMPTION">{{trxns.consumption}}</td>
                                      <td class="align-middle text-right" data-title="RATE">{{ parseFloat(trxns.amountdue) + parseFloat(trxns.discount) - parseFloat(trxns.surcharge) | toMoney }}</td>
                                      <td class="align-middle text-right" data-title="DISCOUNT">{{trxns.discount}}</td>
                                      <td class="align-middle text-right" data-title="SURCHARGE">{{trxns.surcharge}}</td>
                                      <td class="align-middle text-right" data-title="AMOUNT DUE">{{trxns.amountdue}}</td>
                                    </tr>
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </tbody>
              </table>
              </div>
              <div class="card-footer">
                <div class="row col-12 p-0 m-0">
                    <div class="col-9 p-0">
                      <pagination :data="payments" @pagination-change-page="getResults" show-disabled :limit="1"></pagination>
                    </div>
                    <div class="text-right col-3 p-0">
                      
                    </div>
                </div>
              </div>
            </div>

          </div>
          <!-- /.col-md-6 -->
        </div>
        <!-- /.row -->
      </div><!-- /.container-fluid -->
    </div>
    <!-- /.content -->
  </div>
  <!-- /.content-wrapper -->
</template>

<script>

import {mapGetters} from 'vuex'
import axios from 'axios'
import Loader from '@/components/Loader'
import Form from 'vform'
import moment from 'moment'
import DateRangePicker from "vue2-daterange-picker";
import "vue2-daterange-picker/dist/vue2-daterange-picker.css";
import JsonExcel from "vue-json-excel";

export default {
  name: 'Financial',

  components: {
    Loader, DateRangePicker, JsonExcel
  },

  data() {
    let today = new Date();

    let yesterday = new Date();
    yesterday.setDate(today.getDate() - 1);

    let days6 = new Date();
    days6.setDate(today.getDate() - 6);

    return {
      datePickerSettings: {
        dateRange: {
          startDate: moment(String(new Date(today))).format('MM/01/YYYY'),
          endDate: moment(String(new Date(today))).format('MM/DD/YYYY'), //Date.now(),
        },
        minDate: "01/01/2021",
        maxDate: moment(String(new Date(today))).format('MM/DD/YYYY'), //Date.now(),
        ranges: {
          "Today ": [today, today],
          "Yesterday ": [yesterday, yesterday],
          "7 days": [days6, today],
          "This Month": [
            new Date(today.getFullYear(), today.getMonth(), 1),
            today,
          ],
          "Last Month": [
            new Date(today.getFullYear(), today.getMonth() - 1, 1),
            new Date(today.getFullYear(), today.getMonth(), 0),
          ],
        },
        locale: {
          direction: "ltr",
          format: "mm/dd/yyyy",
          separator: " - ",
          applyLabel: "Apply",
          cancelLabel: "Cancel",
          weekLabel: "W",
          customRangeLabel: "Custom Range",
          daysOfWeek: ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"],
          monthNames: [
            "January",
            "February",
            "March",
            "April",
            "May",
            "June",
            "July",
            "August",
            "September",
            "October",
            "November",
            "December",
          ],
          firstDay: 1,
        },
      },
      json_fields: {
        "DATE": 'payment_date',
        "CONSUMER NAME": 'consumer_name',
        "O.R. N.O.": 'or_no',
        "WATER FEE": {
            callback: (value) => {
                return `${ parseFloat(value.amountdue) + parseFloat(value.discount) - parseFloat(value.surcharge) }`;
            }
        },
        "DISCOUNT": 'discount',
        "SURCHARGE": 'surcharge',
        "MEMBERSHIP FEE": 'fee_membership',
        "CONNECTION FEE": 'fee_connection',
        "RECONNECTION FEE": 'fee_reconnection',
        "SERVICE FEE": 'fee_service',
        "THREADING FEE": 'fee_threading',
        "MATERIALS": 'fee_materials',
        "WATER METER": 'fee_meter',
        "OTHERS": 'fee_others',
        "TOTAL": 'fee_total',
      },
      header: [],
      filename: '',
      footer: '',
      worksheet: '',
      //
      json_meta: [
        [
          {
            key: "charset",
            value: "utf-8",
          },
        ],
      ],
      toggled: [],
      loading: false,
      payments : {},
      form: new Form({
        id: '',
      }),
    }
  },

  computed: {
    ...mapGetters(['user']),

    years() {
      const year = new Date().getFullYear()
      return Array.from({length: year - 2019}, (value, index) => 2020 + index)
    },

  },

  methods: {

    toggleToast() {
      this.$swal.fire({
        icon: 'info',
        title: 'Setup this view first'
      })
    },

    async fetchExcelData(){
      const response = await axios.get('api/payments/transactions/np/range/'+ moment(String(this.datePickerSettings.dateRange.startDate)).format('YYYY-MM-DD') + '/' + moment(String(this.datePickerSettings.dateRange.endDate)).format('YYYY-MM-DD'));
      this.header = ['AS-IS/BALAYONG BWP-RURAL WATERWORKS & SANITATION ASSOCIATION, INC.', 'COLLECTION REPORT', moment(String(this.datePickerSettings.dateRange.startDate)).format('MMMM DD, YYYY') + ' to ' + moment(String(this.datePickerSettings.dateRange.endDate)).format('MMMM DD, YYYY') ]
      this.worksheet = 'COLLECTION-REPORT';
      this.filename = 'COLLECTION-REPORT-' + moment(String(this.datePickerSettings.dateRange.startDate)).format('YYYYMMDD') + '-' + moment(String(this.datePickerSettings.dateRange.endDate)).format('YYYYMMDD') + '.xls';
      //this.footer = ['- END -'];
      //console.log(response.data)
      return response.data;
    },
    startDownload(){
        this.$Progress.start();
    },
    finishDownload(){
        this.$Progress.finish();
    },

    isActive (item) {
        return this.toggled.indexOf(item) >= 0
    },
    toggleItem (item) {
        const index = this.toggled.indexOf(item)

        if (index >= 0) {
            this.toggled.splice(index, 1)
            return
        }

        this.toggled.push(item)
    },

    computeDiscount: function(fee, current) {
      return (fee / 100) * current;
    },

    getResults(page = 1) {
      this.$Progress.start();
      //axios.get('api/payments/transactions/'+ this.transaction_year + '/' + this.transaction_month + '?page=' + page).then(({ data }) => (this.payments = data));
      axios.get('api/payments/transactions/range/'+ moment(String(this.datePickerSettings.dateRange.startDate)).format('YYYY-MM-DD') + '/' + moment(String(this.datePickerSettings.dateRange.endDate)).format('YYYY-MM-DD') + '?page=' + page).then(({ data }) => (this.payments = data));
      this.$Progress.finish();
    },

    async loadDataPayment(page = 1) {
        this.$Progress.start();
        this.loading = true;
        //let page = this.payments.current_page;
        // Load transaction data
        await axios.get('api/payments/transactions/range/'+ moment(String(this.datePickerSettings.dateRange.startDate)).format('YYYY-MM-DD') + '/' + moment(String(this.datePickerSettings.dateRange.endDate)).format('YYYY-MM-DD') + '?page=' + page).then(({ data }) => (this.payments = data));
        this.loading = false;
        this.$Progress.finish();
    },
  },

  mounted() {
    this.$Progress.start();
    this.loadDataPayment();
    this.$Progress.finish();
  },

}
</script>

<style scoped>

.cursor {
  cursor: pointer;
}

table {
  font-size: 13px;
}

</style>
